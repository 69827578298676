<header>
	<h2 class="d-flex gap-2 align-items-center">
		<mat-icon>live_help</mat-icon>
		<span>Help - {{ title }}</span>
	</h2>
	<button
		mat-icon-button
		aria-label="close dialog"
		disableRipple
		mat-dialog-close
		matTooltip="Close"
		[matTooltipShowDelay]="500">
		<mat-icon>close</mat-icon>
	</button>
</header>

<content>
	<div class="container">
		<div class="row" *ngIf="content">
			<div class="col help-formatting" [innerHTML]="content"></div>
		</div>
		<div *ngIf="vimeoVideoUrl" class="row">
			<div class="col ratio ratio-16x9">
				<iframe class="p-4" [src]="vimeoVideoUrl | safe" allowfullscreen></iframe>
			</div>
		</div>
	</div>
</content>
