<h3>{{ titleText }}</h3>
<span class="mat-small">{{ subtitle }}</span>
<ng-container [formGroup]="formGroup">
	<mat-list *ngIf="items.length > 0 || adding">
		<mat-list-item *ngFor="let item of items; let index = index" class="edit-list-item">
			<ng-container
				[ngTemplateOutlet]="item.edit ? edit : view"
				[ngTemplateOutletContext]="{ control: itemControlArray.controls[index], item }"></ng-container>
		</mat-list-item>
		<mat-list-item *ngIf="adding" class="edit-list-item">
			<ng-container *ngTemplateOutlet="edit; context: { control: newItemControl }"></ng-container>
		</mat-list-item>
	</mat-list>
	<mat-error *ngIf="validationErrors.required">{{ 'The ' + itemType + ' cannot be blank' }}</mat-error>
	<mat-error *ngIf="validationErrors.email">{{ 'The ' + itemType + ' must be an email address' }}</mat-error>
	<app-icon-button
		icon="add"
		color="grey"
		size="small"
		[text]="'Add ' + itemType | uppercase"
		(buttonClicked)="addItem()"
		[disabled]="adding"
		class="add-button mt-2">
	</app-icon-button>
</ng-container>

<ng-template #view let-item="item">
	<div class="d-flex align-items-center w-100 gap-1 item" *ngIf="!item.edit">
		<span class="flex-grow-1 d-inline-block text-truncate">{{ item.name }}</span>
		<button
			mat-icon-button
			disableRipple
			[matTooltip]="'Edit ' + itemType"
			matTooltipShowDelay="500"
			matTooltipPosition="left"
			(click)="editItem(item)"
			type="button"
			class="list-item-button">
			<mat-icon>edit</mat-icon>
		</button>
		<button
			mat-icon-button
			disableRipple
			[matTooltip]="'Remove ' + itemType"
			[matTooltipShowDelay]="500"
			matTooltipPosition="right"
			(click)="confirmRemoveItem(item)"
			type="button"
			class="list-item-button">
			<mat-icon>delete</mat-icon>
		</button>
	</div>
</ng-template>

<ng-template #edit let-control="control" let-item="item">
	<div class="d-flex align-items-center w-100 gap-1">
		<mat-form-field
			class="flex-grow-1"
			shouldLabelFloat="false"
			[class.mat-form-field-invalid]="(validationErrors | json) !== '{}'"
			subscriptSizing="dynamic">
			<input
				matInput
				#itemInput
				[placeholder]="adding ? 'New ' + itemType : ''"
				[formControl]="control"
				(keydown.enter)="updateName(item)" />
		</mat-form-field>
		<button
			mat-icon-button
			[matTooltip]="(adding ? 'Add ' : 'Update ') + itemType"
			matTooltipShowDelay="500"
			matTooltipPosition="left"
			(click)="updateName(item)"
			type="button">
			<mat-icon>done</mat-icon>
		</button>
		<button
			mat-icon-button
			matTooltip="Cancel"
			matTooltipShowDelay="500"
			matTooltipPosition="right"
			(click)="cancelEdit()"
			type="button">
			<mat-icon>close</mat-icon>
		</button>
	</div>
</ng-template>
